// Stimulus JS
import '@global/turbo-power-initializer';
import { application } from '@/src/global/stimulus-initializer';
import StimulusControllerResolver, {
  createViteGlobResolver
} from '@global/stimulus-resolver';
import MobileNavController from '@/src/jobseeker/main/js/controllers/mobile-nav-controller';
import CookiehubController from '@/src/shared/js/controllers/cookiehub-controller';
import SiteSearchController from '@/src/jobseeker/main/js/controllers/site-search-controller';
import SliderController from './controllers/slider-controller';
import YTooltipController from '@yobbers/js/controllers/y-tooltip-controller';
import YChoiceTagsController from '@yobbers/js/controllers/y-choice-tags-controller';
import YLazyloadController from '@yobbers/js/controllers/y-lazyload-controller';
import ModalController from '@yobbers/js/controllers/modal-controller';
import YFormController from '@yobbers/js/controllers/y-form-controller';
import ScrollActionController from '@jobseeker/account/js/controllers/scroll-action-controller';
import DropdownController from '@yobbers/js/controllers/dropdown-controller';
import ItemSelectorController from '@yobbers/js/controllers/item-selector-controller';
import Rollbar from 'rollbar';
import YTabsController from '@yobbers/js/controllers/y-tabs-controller';
import { initRipplet } from '@global/addons/ripple-effect';
import '@/src/global/turbo-confirm-alert';
import OverlayController from './controllers/overlay-controller';
import DynamicSearchPlaceholder from '@/src/jobseeker/main/js/controllers/dynamic-search-placeholder-controller';

const isProduction = import.meta.env.PROD;
const rollbarClientToken = import.meta.env.VITE_ROLLBAR_CLIENT_TOKEN;

application.register('cookiehub', CookiehubController);

// Workaround for the Rollbar error "Uncaught ReferenceError: _AutofillCallbackHandler is not defined"
window._AutofillCallbackHandler =
  window._AutofillCallbackHandler || function () {};

// Add a specific domain to the ignoredDomains array if you want this message not to appear in Rollbar. A ignored domain
// you can find the filename in the JSON object of the error in the Rollbar dashboard.
const ignoredFileNames = [
  '[native code]',
  '(unknown)',
  "SyntaxError: Unexpected identifier 'https'",
  'webkit-masked-url://hidden/',
  'https://cookiehub.net/c2/74db15ae.js',
  'https://sc-static.net/scevent.min.js'
];

const rollbarConfig = {
  accessToken: rollbarClientToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  hostSafeList: [/.+\.yobbers\.com/],
  payload: {
    environment: 'production',
    client: {
      javascript: {
        source_map_enabled: true,
        guess_uncaught_frames: true
      }
    }
  },
  // Add a specific message to the ignoredMessages array if you want this message not to appear in Rollbar.
  // Has to be defined here other wise the ignoredMessages array will be ignored.
  ignoredMessages: [
    /Fetch is aborted/,
    /Failed to fetch\.?/,
    /The operation is insecure\./,
    /Can't find variable: fbq\./,
    /Can't find variable: ttq\./,
    /Can't find variable _AutofillCallbackHandler/,
    /AbortError: Fetch is aborted/,
    /Request aborted/,
    /Network Error/,
    /Script error\./,
    /The operation was aborted\./,
    /Fetch was aborted\./,
    /timeout of 0ms exceeded/,
    /returnfalse is not defined/,
    /server_fp is not defined/,
    /Can't find variable: __firefox__/,
    /Abgebrochen/,
    /Load failed/,
    /Unexpected EOF/,
    /Can't find variable: __AutoFillPopupClose__/,
    /\(unknown\): Script error\./,
    /AbortError: The user aborted a request/,
    /AbortError: signal is aborted without reason/,
    /The user aborted a request\./,
    /Can't find variable _AutofillCallbackHandler/,
    /Error: \[splide\] A track\/list element is missing\./,
    /NotFoundError: The object can not be found here\./,
    /Error: \[splide\] A track\/list element is missing\./,
    /TypeError: window.Trengo.Api.Widget is undefined/,
    /TypeError: NetworkError when attempting to fetch resource/,
    /Error: 0/
  ],
  checkIgnore: function (isUncaught, args, payload) {
    // Check if the error message matches any regex in the ignoredMessages array
    if (args && args[0] && typeof args[0] === 'string') {
      const errorMessage = args[0];
      for (const pattern of this.ignoredMessages) {
        if (pattern.test(errorMessage)) {
          return true;
        }
      }
    }

    // Check if the filename is in the ignoredFileNames array
    if (payload.body.trace && payload.body.trace.frames) {
      const filename = payload.body.trace.frames[0].filename;
      if (ignoredFileNames.includes(filename)) {
        return true;
      }
    }

    return false;
  }
};

if (isProduction && rollbarClientToken) {
  Rollbar.init(rollbarConfig);

  if (application && typeof application.handleError === 'function') {
    application.handleError = (error, message, detail) => {
      Rollbar.error(error, { message, detail });
    };
  }
}

document.addEventListener('turbo:load', () => initRipplet());

// Cherrypicked controllers
application.register('modal', ModalController);
application.register('mobile-nav', MobileNavController);
application.register('site-search', SiteSearchController);
application.register('slider', SliderController);
application.register('y-lazyload', YLazyloadController);
application.register('scroll-action', ScrollActionController);
application.register('y-form', YFormController);
application.register('dropdown', DropdownController);
application.register('tooltip', YTooltipController);
application.register('y-choice-tags', YChoiceTagsController);
application.register('y-tabs', YTabsController);
application.register('overlay', OverlayController);
application.register('item-selector', ItemSelectorController);
application.register('dynamic-search-placeholder', DynamicSearchPlaceholder);

// Load controller when connected
StimulusControllerResolver.install(
  application,
  createViteGlobResolver(
    import.meta.glob('./controllers/*-controller.{js,ts,jsx}'),
    import.meta.glob('@global/controllers/*-controller.{js,ts}'),
    import.meta.glob('@shared/js/controllers/*-controller.{js,ts}'),
    import.meta.glob('@/src/tracking/controllers/*-controller.{js,ts}')
  )
);

export { application };
